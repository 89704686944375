<template>
  <div>
    <transition mode="out-in">
      <div v-if="!isLoading && isAuthenticated" class="text-center">
        <div class="mb-3">
          <img :src="user.picture" class="img-fluid rounded-circle">
        </div>
        <h1>{{ user.nickname }}</h1>
        <h2>{{  user.name }}</h2>
        <p class="lead">{{ user.email }}</p>

        <div>
          <logout-button class="mb-5" />
        </div>

        <div class="text-start">
          <pre>{{ user }}</pre>
          <pre>{{ roles }}</pre>
        </div>

      </div>
    </transition>
  </div>
</template>
<script setup>
import { useAuth0 } from '@auth0/auth0-vue';
import LogoutButton from "@/components/LogoutButton.vue";
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const { user, isAuthenticated, isLoading } = useAuth0();
const roles = computed(() => store.state.roles)

</script>
