<template>

    <button type="button" class="ms-auto btn btn-link btn-sm" @click.prevent="logoutUser">
      Logout
    </button>

</template>

<script setup>
import { useAuth0 } from '@auth0/auth0-vue';
const { logout } = useAuth0();

const logoutUser = () => logout({
  logoutParams: { returnTo: window.location.origin }
})
</script>
